<template>
  <div>
    <PageDynamicBreadcrumb custom-current-page="Overview" />
    <PageHeader title="Inventory Overview"></PageHeader>

    <DataTable
      table-key="InventoryOverview"
      row-key="business_id"
      title="Inventory"
      :columns="columns"
      :rows="businessInventoryData"
      :loading="loading"
      :downloadable="true"
      :refreshable="false"
      :customizable="false"
      :filterable="true"
      :searchable="true"
      :disable-state="true"
    >
      <template v-slot:connected_sources="row">
        <router-link
          :to="{
            name: 'InventorySourcesOverview',
            query: { business_id: row.business_id }
          }"
        >
          {{ Intl.NumberFormat().format(row.connected_sources) }}
        </router-link>
      </template>
    </DataTable>
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
import PageHeader from "@/components/UI/PageHeader";
import PageDynamicBreadcrumb from "@/components/UI/PageDynamicBreadcrumb";
import DataTable from "@/components/DataTable";
moment.locale("en");

export default {
  name: "BusinessInventoryOverview",
  components: { DataTable, PageDynamicBreadcrumb, PageHeader },
  data() {
    return {
      loading: true,
      filter: "",
      businesses: [],
      columns: [
        {
          name: "business_id",
          label: "Business ID",
          field: "business_id",
          sortable: true,
          sort: (a, b) => this.sortInteger(a, b),
          type: "dimension"
        },
        {
          name: "business_name",
          label: "Business Name",
          field: "business_name",
          sortable: true,
          type: "dimension"
        },
        {
          name: "connected_sources",
          label: "Connected Sources",
          field: "connected_sources",
          hideField: true,
          sortable: true,
          sort: (a, b) => this.sortInteger(a, b),
          type: "metric",
          metricType: "number"
        }
      ]
    };
  },
  computed: {
    businessInventoryData() {
      return this.loading ? [] : this.businesses;
    }
  },
  mounted() {
    axios({
      url:
        "https://api-v2.jumpfeed.com/internal/inventory-sources/businesses-connected"
    })
      .then(resp => {
        this.businesses = resp.data.data;
        this.loading = false;
      })
      .catch(err => {
        console.log(err);
      });
  }
};
</script>

<style scoped lang="scss"></style>
